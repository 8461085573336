<template>
  <div>
    <CustomHeader ref="header"></CustomHeader>
    <!-- 第一部分 -->
    <div class="section">
      <div class="banner">
        <div class="banner-item">
          <div class="slogan">
            <p class="p1">ABOUT US</p>
            <p class="p2">与艾航科技共成长</p>
            <p class="p3" style="width: 100%">艾航与千行百业一起 用自动飞行赋能行业生产力</p>
          </div>
        </div>
      </div>
      <div class="wrap">
        <img src="https://cdn.aihangtec.com/index/image/about/top-bg.png" class="top-bg" />
        <div class="main one-inner">
          <h1 class="h1">公司简介</h1>
          <Title title="Company Profile"></Title>
          <p class="text">
            杭州艾航科技有限公司成立于2015年，位于浙江省杭州市余杭区，公司专注于行业级无人机、行业应用机器人等智能终端设备的应用与设计研发，始终秉承“服务为体·科技为用”的宗旨，致力于为客户定制行业细分领域的专业解决方案。
          </p>
          <p class="text mt-2">
            公司成立之初以承接高校的科研及实验类项目为主。积累了深厚的研发及方案设计经验后，自2016年起，公司逐步开展智慧电力、智慧城市、新能源等多行业细分领域的综合解决方案业务。自主研发了无人机远程自动化控制平台、无人机自动化巡检作业系统、无人机综合管理系统以及其他多种智慧化终端系统。公司已成功为国家电网、网络运营商（中国移动与中国电信）、杭州公安系统、乌镇世界互联网大会等多家单位提供应用解决方案与服务。目前已成为一家合作方覆盖电力、应急、石化、安防、水利水务、智慧城市等多领域的技术综合型科技企业。
          </p>
        </div>
      </div>
    </div>
    <!-- 第二部分 -->
    <div class="section honor">
      <div class="main two-inner">
        <h1 class="h1">荣誉资质</h1>
        <Title title="Honorary Qualification"></Title>
        <div class="tab-wrap">
          <div class="tab core">
            <div ref="one" @click="nav($event, index)" v-for="(item, index) in tab" :key="index"
              :class="isActive == index ? 'active' : ''">
              {{ item }}
            </div>
            <div ref="bar" class="tab-bar"></div>
          </div>
          <div v-show="isActive == 0" class="inner core">
            <div class="swiper-container opcity">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in honorList" :key="item.url">
                  <div class="center">
                    <div class="center-item">
                      <el-image class="slide-item" :src="item.url" :preview-src-list="[item.url]"> </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-container unopcity">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in m_honor" :key="item.url">
                  <div class="center">
                    <div class="center-item">
                      <el-image class="slide-item" fit="contain" v-for="img in item" :key="img.url" :src="img.url"
                        :preview-src-list="[img.url]"> </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="isActive == 1" class="inner core">
            <div class="swiper-container opcity">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in konwList" :key="item.url">
                  <div class="center">
                    <div>
                      <el-image class="slide-item" :src="item.url" :preview-src-list="[item.url]"> </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-container unopcity">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in m_konw" :key="item.url">
                  <div class="center">
                    <div class="center-item">
                      <el-image class="slide-item" fit="contain" v-for="img in item" :key="img.url" :src="img.url"
                        :preview-src-list="[img.url]"> </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第三部分 -->
    <div class="section">
      <div class="wrap-bg">
        <div class="main three-inner">
          <h1 class="h1">新闻动态</h1>
          <Title title="News Information"></Title>
          <div class="flex new-content">
            <div>
              <el-image class="new-img" :src="newList[isNew].url" :preview-src-list="[newList[isNew].url]"> </el-image>
            </div>
            <div class="new-wrap">
              <div class="new-item" v-for="(item, index) in newList" :key="index"
                :class="isNew == index ? 'newActive' : ''" @click="handleNew(item, index)">
                <div class="new-num">
                  <div class="big" :class="isNew == index ? 'bigNum' : ''">{{ item.index }}</div>
                  <div>{{ item.time }}</div>
                </div>
                <div class="new-inner" :class="isNew == index ? 'new-innerActive' : ''">
                  <div>{{ item.title }}</div>
                  <div class="link opcity">
                    <a :href="item.link" target="_blank"><img v-if="isNew == index"
                        src="https://cdn.aihangtec.com/index/image/about/link.png" class="link-icon" />
                      <img v-else src="https://cdn.aihangtec.com/index/image/about/link_a.png" class="link-icon" />
                      查看链接</a>
                  </div>
                </div>
              </div>
              <div class="btn opcity">
                <div class="btn-left" @click="prev">
                  <img src="https://cdn.aihangtec.com/index/image/about/left.png" />
                </div>
                <div class="btn-right" @click="next">
                  <img src="https://cdn.aihangtec.com/index/image/about/right.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="unopcity">
            <div class="media-btn">
              <div class="n-btn" @click="prev">上一页</div>
              <div>{{ this.page + 1 }}/2</div>
              <div class="n-btn" @click="next">下一页</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第四部分 -->
    <div class="section">
      <div class="wrap-bot">
        <div class="wrap-back">
          <div class="main four-inner">
            <h1 class="h1">企业精神</h1>
            <Title title="Enterprise Vision" back="rgb(209,212,218)"></Title>
            <div class="vision">
              <p>服务为体，科技为用</p>
              <p>成为全球领先的人工智能公司</p>
              <p class="line">专注于宽度1厘米，垂直100米的行业细分领域，把握机遇创新，成为国内无人机行业解决方案开拓者和引领者，脚踏实地了解行业需求，结合实际提供专业解决方案。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Fixed class="opcity"></Fixed>
    <CustomFooter></CustomFooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFirst: true,
      isActive: 0,
      isNew: 0,
      page: 0,
      tab: ["荣誉证书", "知识产权"],
      swiper: {},
      time: {},
      list: [
        {
          time: "2021.12.30",
          index: "01",
          title: "当潮汐遇上光伏|全国首例潮光互补型光伏电站在台州投运，打造潮汐与光伏协调运行发电的新模式，让1985年投产的全国最大潮汐能发电站——江厦潮汐试验电站实现旧貌换新颜，潮光互补，绿电涌动。",
          url: "https://cdn.aihangtec.com/index/image/about/n1.jpg",
          link: "http://zj.people.com.cn/n2/2021/1230/c186327-35075688.html"
        },

        {
          time: "2021.12.09",
          index: "02",
          title: "打造智能电网，温岭在国内首创无人机配网架空线路全自动巡检模式，积极探索无人机智能应用，随着当地首个电力智能巡检机场的投运，巡检工作突破了信号和续航的限制，率先在国内实现对架空线路的全自动巡检。",
          url: "https://cdn.aihangtec.com/index/image/about/new1.png",
          link: "https://www.cpnn.com.cn/qiye/guanli/202112/t20211213_1465378_wap.html"
        },
        {
          time: "2021.11.29",
          index: "03",
          title: "效率提升40倍！创新配网无人机全自动巡检模式,部署温岭石塘盛阳网格点，开展配网无人机应用，革新运维、检修、消缺等工作模式，促进配网运检管理模式革新，创新配网无人机全自动巡检模式，效率提升40倍。",
          url: "https://cdn.aihangtec.com/index/image/about/new1.webp",
          link: "https://mp.weixin.qq.com/s/mEK3U2KZUw97eLWeDlXisg"
        },
        {
          time: "2020.11.24",
          index: "04",
          title: "直播|国网温岭市供电公司“无人值守智能专业仓+无人机空投”助力电力抢修提速增效",
          url: "https://cdn.aihangtec.com/index/image/about/new3.jpeg",
          link: "https://www.cpnn.com.cn/qiye/guanli/202108/t20210823_1421541_wap.html"
        },
        {
          time: "2021.11.12",
          index: "05",
          title:
            "台州电力专用载货无人机启用 一次可承重120公斤。近日在温岭朝光互补智能光伏发电项目110kv送出工程项目现场，国网台州供电公司使用重载无人机分批次将设施材料快速投放到山上的安装现场，一个来回不到两分钟，该无人机可实现低空近地面单次120kg物资安全释放，首次承重相当于两个成年人的体重。",
          url: "https://cdn.aihangtec.com/index/image/about/new2.jpeg",
          link: "https://wap.cztv.com/tv/44/1406016.html?play=1"
        },
        {
          time: "2021.10.01",
          index: "06",
          title: "浙江乐清市供电公司：“黑科技”助力雁荡山景区国庆保供电应急演练。演练运用无人机自主巡视、5G图传设备、通道可视化设备等多项保电“黑科技”装备以最佳状态为国庆小长假安全用电保驾护航。",
          url: "https://cdn.aihangtec.com/index/image/about/n6.jpg",
          link: "http://www.am810.net/9236899.html"
        },
        {
          time: "2019.05.15",
          index: "07",
          title: "配网无人机智能巡检管理中心落户温岭石塘 全国首家",
          url: "https://cdn.aihangtec.com/index/image/about/n7.webp",
          link: "https://baijiahao.baidu.com/s?id=1633560142128158777&wfr=spider&for=pc"
        },
        {
          time: "2019.04.20",
          index: "08",
          title: "温岭：率先启用配网无人机自动巡线，提高工作效率6倍多。",
          url: "https://cdn.aihangtec.com/index/image/about/n8.png",
          link: "https://mp.weixin.qq.com/s/hv9n0PcWx2roOvEJ6NgmoA"
        }
      ],
      newList: [
        {
          time: "2021.12.30",
          index: "01",
          title: "当潮汐遇上光伏|全国首例潮光互补型光伏电站在台州投运，打造潮汐与光伏协调运行发电的新模式，让1985年投产的全国最大潮汐能发电站——江厦潮汐试验电站实现旧貌换新颜，潮光互补，绿电涌动。",
          url: "https://cdn.aihangtec.com/index/image/about/n1.jpg",
          link: "http://zj.people.com.cn/n2/2021/1230/c186327-35075688.html"
        },

        {
          time: "2021.12.09",
          index: "02",
          title: "打造智能电网，温岭在国内首创无人机配网架空线路全自动巡检模式，积极探索无人机智能应用，随着当地首个电力智能巡检机场的投运，巡检工作突破了信号和续航的限制，率先在国内实现对架空线路的全自动巡检。",
          url: "https://cdn.aihangtec.com/index/image/about/new1.png",
          link: "https://www.cpnn.com.cn/qiye/guanli/202112/t20211213_1465378_wap.html"
        },
        {
          time: "2021.11.29",
          index: "03",
          title: "效率提升40倍！创新配网无人机全自动巡检模式,部署温岭石塘盛阳网格点，开展配网无人机应用，革新运维、检修、消缺等工作模式，促进配网运检管理模式革新，创新配网无人机全自动巡检模式，效率提升40倍。",
          url: "https://cdn.aihangtec.com/index/image/about/new1.webp",
          link: "https://mp.weixin.qq.com/s/mEK3U2KZUw97eLWeDlXisg"
        },
        {
          time: "2020.11.24",
          index: "04",
          title: "直播|国网温岭市供电公司“无人值守智能专业仓+无人机空投”助力电力抢修提速增效",
          url: "https://cdn.aihangtec.com/index/image/about/new3.jpeg",
          link: "https://www.cpnn.com.cn/qiye/guanli/202108/t20210823_1421541_wap.html"
        }
      ],
      m_honor: [],
      honorList: [
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/20.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/1.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/2.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/3.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/4.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/5.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/6.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/7.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/8.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/9.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/10.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/11.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/12.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/13.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/14.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/15.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/16.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/17.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/18.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/honor/19.jpg"
        }
      ],
      m_konw: [],
      konwList: [
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/1.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/2.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/3.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/4.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k1.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k2.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k3.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k4.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k5.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k6.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k7.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k8.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k9.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k10.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k11.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k12.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k13.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k14.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k15.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k16.jpg"
        },
        {
          name: "",
          url: "https://cdn.aihangtec.com/index/image/about/konw/k17.jpg"
        }
      ]
    }
  },
  mounted() {
    //如果是移动端 一个切换swiper块四张图
    //如果是PC端 一个切换swiper块一张图
    //num 控制swiper初始化
    let num = 4
    if (document.body.clientWidth < 780) num = 1
    this.swiper = new this.Swiper(".swiper-container", {
      slidesPerView: num,
      spaceBetween: 30,
      autoplay: true,
      observer: true,
      observeParents: true
    })
    this.mobile()
    this.new()
  },
  methods: {
    mobile() {
      //如果是移动端 一个切换swiper块四张图
      //如果是PC端   一个切换swiper块一张图
      let list = JSON.parse(JSON.stringify(this.honorList))
      let length = list.length / 4
      for (let i = 0; i < length; i++) {
        this.m_honor.push(list.splice(0, 4))
      }
      let list2 = JSON.parse(JSON.stringify(this.konwList))
      let length2 = list2.length / 4
      for (let i = 0; i < length2; i++) {
        this.m_konw.push(list2.splice(0, 4))
      }
    },
    new() {
      //新闻自动切换，弄个定时器
      this.time = setInterval(() => {
        this.isNew++
        if (this.isNew == 4) this.isNew = 0
      }, 3000)
    },
    prev() {
      //新闻上一页
      if (this.page > 0) {
        this.page--
        const num = this.page * 4
        if (this.list[num]) {
          this.isNew = 0
          this.newList = this.list.slice(num, num + 4)
        }
      } else if (this.page == 0) {
        this.page = 1
        const num = this.page * 4
        if (this.list[num]) {
          this.isNew = 0
          this.newList = this.list.slice(num, num + 4)
        }
      }
    },
    next() {
      //新闻下一页
      if (this.page < 1) {
        this.page++
        const num = this.page * 4
        if (this.list[num - 1]) {
          this.isNew = 0
          this.newList = this.list.slice(num, num + 4)
        }
      } else if (this.page * 4 + 4 >= this.list.length) {
        this.page = this.isNew = 0
        this.newList = this.list.slice(0, 4)
      }
    },
    nav(e, i) {
      //公司荣誉和证书切换
      const bar = e.target
      this.isActive = i
      const ibar = document.getElementsByClassName("tab-bar")[0]
      this.$refs.bar.style.transform = `translateX(${bar.offsetLeft + (bar.clientWidth - ibar.offsetWidth) / 2}px)`
    },
    handleNew(item, index) {
      //新闻点击跳入其他网站
      if (document.body.clientWidth < 780 && this.isNew == index) {
        window.open(item.link)
      } else {
        this.isNew = index
        clearTimeout(this.time)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$swiper_height: 403px;

p {
  margin: 0;
}

//opcity为pc端显示 而 移动端不显示
.opcity {
  @media screen and (max-width: 780px) {
    display: none !important;
  }

  @media screen and (min-width: 780px) {
    display: block;
  }
}

//unopcity为pc端不显示 而 移动端显示
.unopcity {
  @media screen and (max-width: 780px) {
    display: block;
  }

  @media screen and (min-width: 780px) {
    display: none;
  }
}

.banner {
  margin: 0;
  position: relative;
  top: 0;
}

.banner-item {
  background-image: url(https://cdn.aihangtec.com/index/image/about/about.png);
}

.section {
  position: relative;
}

.top-bg {
  @media screen and (max-width: 780px) {
    height: 125px;
    top: -25px;
  }

  @media screen and (min-width: 780px) {
    height: 60vh;
    top: -13vh;
  }

  position: absolute;
  width: 100%;
  object-fit: fill;
  z-index: 999;
}

.text {
  @media screen and (max-width: 780px) {
    font-size: 13px;
    padding: 0 20px;
  }
}

.main {
  position: relative;

  @media screen and (max-width: 780px) {
    width: 100vw;
  }

  @media screen and (min-width: 780px) {
    width: 1200px;
  }

  margin: 0 auto;
  // min-height: 300px;
  z-index: 999;
}

.tab-wrap {
  position: relative;
  height: 50px;

  @media screen and (max-width: 780px) {
    padding-top: 20px;
    margin: 0 20px;
  }

  @media screen and (min-width: 780px) {
    font-size: 20px;
    margin: 40px 20px;
  }

  background: #ffffff;
  border-bottom: 1px solid #d8d8d8;

  .tab {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    color: #333333;
    letter-spacing: 1px;

    @media screen and (max-width: 780px) {
      font-size: 10px;
    }

    @media screen and (min-width: 780px) {
      font-size: 20px;
    }

    font-weight: 400;

    &>div {
      user-select: none;
      transition: all 0.3s;
      cursor: pointer;
    }
  }

  .tab-bar {
    position: absolute;
    height: 2px;

    @media screen and (max-width: 780px) {
      width: 44px;
      top: 28px;
      font-size: 10px;
      transform: translateX(0px);
    }

    @media screen and (min-width: 780px) {
      font-size: 20px;
      width: 56px;
      top: 49px;
      transform: translateX(14px);
    }

    background: var(--RootColor);
    border-radius: 2px;
    transition: all 0.3s;
  }

  .inner {
    margin-top: 45px;
  }

  .active {
    color: var(--RootColor);
  }
}

.wrap {
  position: relative;
  overflow: visible;
}

.wrap-back {
  display: flex;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 780px) {
    height: 50vh;
  }

  @media screen and (min-width: 780px) {
    height: 100vh;
  }

  background: rgba(#fff, 0.79);
}

.wrap-bot {
  @media screen and (max-width: 780px) {
    height: 50vh;
  }

  @media screen and (min-width: 780px) {
    height: 100vh;
  }

  background-image: url(https://cdn.aihangtec.com/index/image/about/bot-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.wrap-bg {
  @media screen and (max-width: 780px) {
    // height: 50vh;
  }

  @media screen and (min-width: 780px) {
    height: 100vh;
  }

  background-image: url(https://cdn.aihangtec.com/index/image/about/about-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.one-inner {
  padding: -20px 0 10px 0;
}

.two-inner {
  padding: 8% 0 10px 0;
}

.three-inner {
  @media screen and (max-width: 780px) {
    padding: 0 0 8% 0;
    overflow: hidden;
  }

  @media screen and (min-width: 780px) {
    padding: 8% 0 8% 0;
  }
}

.four-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex {
  display: flex;
  justify-content: center;
}

.new-content {
  margin-top: 3%;
}

.honor {
  @media screen and (max-width: 780px) {
    min-height: 620px;
    background: #ffffff !important;
  }

  @media screen and (min-width: 780px) {
    display: block;
    min-height: 800px;
    background: #ffffff !important;
  }
}

.newActive {
  left: -15%;

  @media screen and (max-width: 780px) {
    width: 117% !important;
  }

  @media screen and (min-width: 780px) {
    width: 115% !important;
  }

  color: #ffffff !important;
  background: linear-gradient(90deg, #254ecb 0%, #082674 100%);
  padding-left: 17.5% !important;
}

.new-innerActive {
  .link {
    border: #fff 1px solid !important;

    a {
      color: #fff !important;
    }
  }
}

.new-wrap {
  position: relative;

  @media screen and (max-width: 780px) {
    margin-left: 6px;
    padding: 8px 0;
  }

  @media screen and (min-width: 780px) {
    margin-left: 36px;
    padding: 18px 0;
  }

  .new-item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 780px) {
      padding: 3px 8px;
      font-size: 10px;
    }

    @media screen and (min-width: 780px) {
      padding: 13px 18px;
      font-size: 18px;
    }

    font-weight: 500;
    color: #8e8e93;

    @media screen and (max-width: 780px) {
      line-height: 15px;
    }

    @media screen and (min-width: 780px) {
      line-height: 27px;
    }

    border-radius: 10px;
    transition: all 0.5s;
    cursor: pointer;

    .new-num {
      position: relative;
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .big {
      @media screen and (max-width: 780px) {
        font-size: 10px;
        line-height: 16px;
      }

      @media screen and (min-width: 780px) {
        font-size: 30px;
        line-height: 38px;
      }

      text-align: center;
      font-weight: 600;
      color: #000000;
    }

    .bigNum {
      color: #fff;
    }

    .new-num div:last-child {
      @media screen and (max-width: 780px) {
        font-size: 6px;
        // margin-top: 2px;
      }

      @media screen and (min-width: 780px) {
        margin-top: 15px;
      }
    }

    .new-inner {
      @media screen and (max-width: 780px) {
        width: 102px;
        font-size: 8px;
        margin-left: 12px;
      }

      @media screen and (min-width: 780px) {
        width: 400px;
        font-size: 16px;
        margin-left: 52px;
      }

      &>div:first-child {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .link {
        width: 100px;
        margin-top: 10px;
        font-size: 12px;
        float: right;
        text-align: center;
        border: var(--RootColor) 1px solid;
        border-radius: 20px;

        a {
          color: var(--RootColor);
          text-decoration: none;
          transition: none;
        }
      }
    }
  }
}

.btn {
  position: absolute;
  right: 0;
  bottom: -35px;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .btn-left {
    background: var(--RootColor);
    user-select: none;

    img {
      position: relative;
      left: -2px;
    }
  }

  .btn-right {
    margin-left: 42px;
    background: var(--RootColor);

    img {
      position: relative;
      left: 2px;
    }
  }

  div {
    width: 30px;
    height: 30px;
    border-radius: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 10px;
      height: 18px;
    }
  }
}

.media-btn {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;

  &>div {
    width: 72px;
    height: 17px;
    text-align: center;
    border-radius: 9px;
  }

  &>div:nth-child(2) {
    font-weight: 400;
    color: #000000;
    line-height: 15px;
  }

  .n-btn {
    line-height: 17px;
    font-size: 8px;
    font-weight: 400;
    background: var(--RootColor);
    color: #ffffff;
  }
}

.new-img {
  @media screen and (max-width: 780px) {
    width: 183px;
    height: 162px;
  }

  @media screen and (min-width: 780px) {
    width: 600px;
    height: 500px;
  }

  border-radius: 10px;
  object-fit: cover;
}

.swiper-container {
  width: 100%;
  overflow: hidden;
  height: $swiper_height;
  user-select: none;

  .center {
    display: flex;
    align-items: center;
    position: relative;
    height: $swiper_height;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 21px;
    text-align: center;

    .center-item {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .slide-item {
      @media screen and (max-width: 780px) {
        width: 48%;
        padding: 8px;
        height: 180px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;

        &>img {
          object-fit: contain;
        }
      }

      @media screen and (min-width: 780px) {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.swiper-slide {
  height: $swiper_height;
}

.link-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
}

::v-deep .el-image__inner {
  transition: all 0.3s ease-in;
}

::v-deep .el-image__inner:hover {
  transform: scale(1.05);
}

::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  height: 101vh;
}

.vision {
  padding-top: 2vh;
  text-align: center;

  @media screen and (max-width: 780px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media screen and (min-width: 780px) {
    font-size: 28px;
    line-height: 42px;
  }

  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);

  .line {
    @media screen and (max-width: 780px) {
      font-size: 8px;
      line-height: 26px;
    }

    @media screen and (min-width: 780px) {
      font-size: 24px;
      line-height: 36px;
    }

    margin: 5vh 20px 0;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    opacity: 0.44;
  }
}
</style>
<style>
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: var(--RootColor) !important;
}

.fp-tableCell {
  vertical-align: inherit;
}
</style>
